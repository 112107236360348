<template>
  <div class="page fill-height d-flex flex-column gap-6 pt-4">
    <loading-overlay v-if="loading" :message="loadingMessage" show></loading-overlay>
    <location-permission-dialog
      :visible="askingForLocation"
      @agree="retrieveLocation()"
      @disagree="onNoGpsAllowed('denied')"></location-permission-dialog>

    <comet-navbar primary class="px-4">
      <language-selector v-if="started" />
    </comet-navbar>
    <div v-if="!started" class="d-flex flex-column gap-6 px-4 my-auto">
      <language-selector />
      <h1 class="d-flex flex-column gap-2">
        <span class="welcome">Welcome to</span>
        <span class="title globalseo-exclude">{{ warehouseData.name }}</span>
      </h1>
      <p class="instructions">Tap below to begin your check-in process.</p>
      <v-btn
        block
        append-icon="mdi-arrow-right"
        color="primary"
        :disabled="!location.settled"
        @click="handleStart()">
        Start check-in
      </v-btn>
    </div>

    <appointment-form
      v-if="started"
      :latitude="String(location.latitude)"
      :longitude="String(location.longitude)"
      :warehouse="warehouseData"
      @back="started = false"></appointment-form>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import axios from 'axios';
import { get } from 'lodash';
import {
  clearLocalStorageKey,
  updateObjectInLocalStorage
} from '@/components/mixins/localStorageMixin';
import type { IWarehouse } from '@nova/core';

import retrieveLocationMixin from '@/components/mixins/retrieveLocationMixin';
import LanguageSelector from '@/components/LanguageSelector.vue';
import CometNavbar from '@/components/CometNavbar.vue';

export default defineComponent({
  components: {
    LanguageSelector,
    CometNavbar
  },
  mixins: [retrieveLocationMixin],
  async mounted() {
    clearLocalStorageKey('comet:appointment');
    clearLocalStorageKey('comet:warehouse');
    clearLocalStorageKey('comet:isPlanned');
    this.verifyWarehouse();

    if (this.warehouseId) {
      await this.setWarehouse();
      if (this.warehouseData?.id) {
        if (this.warehouseData.settings?.gateManagementRequiresGeofencing) {
          await this.askForLocation();
        } else {
          this.onLocationSuccess();
        }

        // Set on global store
        if (this.warehouseData.settings?.enableMilitaryTime === true) {
          await this.$store.dispatch('setMilitaryTime', true);
        }
      }
    }
  },
  data() {
    return {
      started: false,
      retrievingWarehouse: false,
      warehouseId: '',
      warehouseData: {} as IWarehouse
    };
  },
  computed: {
    loading() {
      return this.calculatingLocation || this.retrievingWarehouse;
    },
    loadingMessage() {
      if (this.calculatingLocation) {
        return 'Retrieving location...';
      } else if (this.retrievingWarehouse) {
        return 'Retrieving warehouse data...';
      }
      return '';
    }
  },
  methods: {
    handleStart() {
      this.started = true;

      this.mixpanel.track('Started: Self Check-in', {
        'Warehouse ID': this.warehouseId || 'N/A',
        'Warehouse Name': this.warehouseData?.name || 'N/A',
        'Org ID': this.warehouseData?.orgId,
        'Org Name': this.warehouseData?.org?.name
      });
    },
    verifyWarehouse() {
      this.warehouseId = (this.$route.query.warehouseId as string) ?? '';

      if (!this.warehouseId) {
        return this.$router.push({
          name: 'warehouse-error',
          params: {
            icon: 'mdi-alert',
            title: 'Missing warehouse',
            message: 'The link is invalid, it must contain the warehouse Id'
          }
        });
      }
    },
    async onLocationSuccess() {
      this.location.settled = true;
      this.trackMixpanelStarted();
    },
    async setWarehouse() {
      try {
        this.retrievingWarehouse = true;
        const res = await this.axiosClient.get(`/checkin/public/${this.warehouseId}/`);
        const data = get(res, 'data.data');
        this.warehouseData = { ...data };
        updateObjectInLocalStorage('comet:warehouse', this.warehouseData);
      } catch (err) {
        if (axios.isAxiosError(err)) {
          const errorParams = this.makeErrorScreenParams(err);

          this.trackMixpanelError(errorParams);

          this.$router.push({
            name: 'warehouse-error',
            params: errorParams
          });
        }
      } finally {
        this.retrievingWarehouse = false;
      }
    },
    trackMixpanelStarted() {
      this.mixpanel.track('Viewed: Self Check-in', {
        'Warehouse ID': this.warehouseId || 'N/A',
        'Warehouse Name': this.warehouseData?.name || 'N/A',
        'Org ID': this.warehouseData?.orgId,
        'Org Name': this.warehouseData?.org?.name,
        Latitude: this.location.latitude || 'N/A',
        Longitude: this.location.longitude || 'N/A'
      });
    },
    trackMixpanelError(errParams: Record<string, unknown>) {
      this.mixpanel.track('Error: Self Check-in', {
        'Appointment Validation #': 'N/A',
        'Warehouse ID': this.warehouseId || 'N/A',
        'Warehouse Name': this.warehouseData?.name || 'N/A',
        'Org ID': this.warehouseData?.orgId,
        'Org Name': this.warehouseData?.org?.name,
        Latitude: this.location.latitude || 'N/A',
        Longitude: this.location.longitude || 'N/A',
        'Error Type': errParams.title,
        'GPS Error Cause': errParams.cause ?? 'N/A'
      });
    },
    onNoGpsAllowed(cause: 'denied' | 'not-available') {
      this.calculatingLocation = false;
      this.trackMixpanelError({
        title: 'GPS Device Error',
        cause
      });
      this.$router.push('/gps-error');
    }
  }
});
</script>

<style scoped>
.page {
  background-color: #fcfcfc;
}

.welcome {
  font-family: Poppins, sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 125%;
  color: #3c3c3c;
}

.title {
  font-family: Poppins, sans-serif;
  font-size: 26px;
  font-style: normal;
  font-weight: 500;
  line-height: 125%;
  color: #1e3036;
}

.instructions {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  color: #3c3c3c;
}
</style>
