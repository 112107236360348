import { ref, computed } from 'vue';
import { useNovaCore } from '@/components/composables';

export default function useCheckinForms(warehouse: any) {
  const novaCore = useNovaCore();

  const assetDetailsFields = ref([] as { key: string; value: unknown; required: boolean }[]);
  const additionalAssetDetailsFields = ref([] as any[]);
  const checkInFields = ref([] as any[]);

  const checkInFieldsExist = computed(() => {
    return checkInFields.value.length;
  });

  const additionalAssetDetailsFieldsExist = computed(() => {
    return additionalAssetDetailsFields.value.length;
  });

  const checkInTriggers = computed(() => {
    return (warehouse.triggers || []).filter(
      (trigger: { feature: string }) => trigger.feature === 'check-in'
    );
  });

  const additionalAssetDetailsTriggers = computed(() => {
    return (warehouse.triggers || []).filter(
      (trigger: { feature: string }) =>
        trigger.feature.includes('check-in') && trigger.feature.includes('additional asset details')
    );
  });

  const computedRequiredAssetVisitFields = computed(() => {
    const requiredAssetVisitFields = [
      {
        label: "Driver's phone number",
        key: 'phone',
        description: '',
        placeholder: '',
        type: novaCore.CustomFieldType.Phone,
        testId: 'comet-asset-phone-field'
      },
      {
        label: 'Carrier company',
        key: 'companyHint',
        description: '',
        placeholder: '',
        type: novaCore.CustomFieldType.String,
        testId: 'comet-asset-company-hint-field'
      }
    ];
    return requiredAssetVisitFields.map((field: any) => {
      const fieldData = {
        key: field.key,
        label: field.label,
        type: field.type,
        value: null,
        disabled: false,
        required: true,
        name: field.id,
        testId: field.testId
      };

      return fieldData;
    });
  });

  const areRequiredAssetDetailsFilled = computed(() => {
    return (
      assetDetailsFields.value
        .filter(field => field.required)
        .every(field => validateRequiredField(field)) &&
      additionalAssetDetailsFields.value
        .filter(field => field.required)
        .every(field => validateRequiredField(field))
    );
  });

  const areRequiredCheckinFieldsFilled = computed(() => {
    return checkInFields.value
      .filter(field => field.required)
      .every(field => validateRequiredField(field));
  });

  function validateRequiredField(field: any) {
    return (
      (field.type !== novaCore.CustomFieldType.Phone && field.value) ||
      (field.type === novaCore.CustomFieldType.Phone && novaCore.isPhoneValid(field.value))
    );
  }

  function setRequiredAssetVisitFields() {
    assetDetailsFields.value = computedRequiredAssetVisitFields.value;
  }

  function prepareCustomFormsData() {
    const formData = [];
    const mapFn = (cf: any) => ({
      formFieldId: cf.name,
      triggerId: cf.triggerId,
      value: cf.value
    });

    if (checkInFieldsExist.value) {
      formData.push(...checkInFields.value.map(mapFn));
    }

    if (additionalAssetDetailsFieldsExist.value) {
      formData.push(...additionalAssetDetailsFields.value.map(mapFn));
    }

    return formData;
  }

  function extractFieldsFromTriggers(triggers: Array<Record<string, any>>) {
    const fields: any[] = [];

    triggers.forEach((trigger: Record<string, any>) =>
      ['formFrom', 'formTo'].forEach(form =>
        trigger.flow[form]?.formFields?.forEach((formField: Record<string, any>) => {
          const customField = {
            label: formField.overrideLabel || formField.field.label,
            type: formField.field.type,
            value: null,
            required: formField.required,
            triggerId: trigger.id,
            name: formField.id,
            dropDownValues: formField.field.extraFields?.dropDownValues,
            maxLengthOrValue: formField.field.extraFields?.maxLengthOrValue,
            minLengthOrValue: formField.field.extraFields?.minLengthOrValue
          };
          fields.push(customField);
        })
      )
    );
    return fields;
  }

  function getAssetDetailsData() {
    const assetDetails: Record<string, unknown> = {};
    assetDetailsFields.value.forEach(field => {
      assetDetails[field.key] = field.value;
    });
    return assetDetails;
  }

  return {
    assetDetailsFields,
    additionalAssetDetailsFields,
    checkInFields,
    checkInFieldsExist,
    additionalAssetDetailsFieldsExist,
    checkInTriggers,
    additionalAssetDetailsTriggers,
    computedRequiredAssetVisitFields,
    areRequiredAssetDetailsFilled,
    areRequiredCheckinFieldsFilled,
    validateRequiredField,
    setRequiredAssetVisitFields,
    extractFieldsFromTriggers,
    getAssetDetailsData,
    prepareCustomFormsData
  };
}
