<template>
  <div class="custom-field">
    <label class="custom-field__label" :class="{ required: field.required }" v-if="field.label">
      {{ `${field.label} ${field.description ? `- (${field.description})` : ''}` }}
    </label>

    <datepicker
      :required="field.required"
      v-model="time"
      :timezone="timezone"
      time-picker-inline
      no-hours-overlay
      no-minutes-overlay
      :state="!!time" />
  </div>
</template>

<script>
import { DateTime } from 'luxon';
import customFieldTypeMixin from '@/components/custom_fields/mixins/customFieldTypeMixin';
import '@vuepic/vue-datepicker/dist/main.css';

export default {
  name: 'custom-field-timestamp',
  mixins: [customFieldTypeMixin],
  data() {
    return {
      time: ''
    };
  },
  beforeMount() {
    if (this.value) {
      this.time = DateTime.fromISO(this.value, {
        zone: this.timezone
      });
    }
  },
  watch: {
    time(value) {
      this.value = value;
    }
  }
};
</script>

<style lang="scss" scoped>
:deep(.dp__input) {
  height: 56px;
  block-size: 56px;
}

:deep(svg.dp__icon.dp__input_icon) {
  margin-top: -28px;
}
</style>
