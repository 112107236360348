<template>
  <v-form id="appointment-form" ref="appointmentForm" class="d-flex flex-column gap-6 flex-grow-1">
    <!--
      In mobile devices the touch event happens instead of the hover event,
      so the hover state is not being reset when the user navigates back to a previous step.
      Ideally we should be able to use @media (hover: hover) {} to solve this, but that would
      require use to re-write the Vuetify theme, and that's not something we can do right now.
    -->
    <v-btn
      :key="currentStep"
      variant="text"
      prepend-icon="mdi-arrow-left"
      style="align-self: flex-start"
      @click="handleBack">
      Back
    </v-btn>

    <div
      class="d-flex flex-column gap-6 px-4 flex-grow-1"
      style="
        padding-bottom: calc(
          72px + 16px
        ); /* Enought space so, in case of overflow, the fixed footer does not overlap the last form field */
      ">
      <template v-if="currentStep === 1">
        <div class="checkin__form-container">
          <div class="checkin__form-header">
            <h2 class="typography-heading-md-bold">What is the purpose of your stop?</h2>
          </div>

          <div class="checkin__form-body">
            <v-radio-group v-model="assetVisitType" hide-details>
              <v-radio
                v-for="option in assetVisitTypes"
                :key="option.value"
                :label="option.label"
                :value="option.value">
                <template #label>
                  <div class="pa-2">
                    <p>
                      <strong>{{ option.label }}</strong>
                    </p>
                    <div class="helper-text">{{ option.helperText }}</div>
                  </div>
                </template>
              </v-radio>
            </v-radio-group>
          </div>
        </div>
      </template>

      <template v-if="currentStep === 2">
        <asset-details-form
          v-model:assetContainerData="assetContainerData"
          :asset-visit-type="assetVisitType"
          :asset-visit-types="assetVisitTypes"
          :asset-container-types="assetContainerTypes"
          :max-container-data="MAX_CONTAINER_DATA" />
      </template>

      <template v-if="currentStep === 3">
        <div class="checkin__form-container">
          <div class="checkin__form-header">
            <h1 class="typography-heading-md-bold">Check-in Details</h1>
          </div>

          <v-alert
            v-if="inlineError.msg"
            dense
            outlined
            type="error"
            :icon="inlineError.icon ?? 'mdi-alert-circle-outline'">
            {{ inlineError.msg }}
          </v-alert>

          <div class="checkin__form-body">
            <!-- These come from Check-in: Asset Details Form for Driver -->
            <custom-fields :warehouse="warehouse" :value="assetDetailsFields"></custom-fields>

            <custom-fields
              :warehouse="warehouse"
              v-if="additionalAssetDetailsFieldsExist"
              :value="additionalAssetDetailsFields"></custom-fields>
          </div>
        </div>

        <div class="checkin__form-container">
          <div class="checkin__form-header">
            <h2 class="typography-heading-md-bold">Appointment information</h2>
            <h3 class="typography-body-sm text__color-text-tertiary">
              Don't worry if you don't have it right now - you can leave this field blank.
            </h3>
          </div>
          <div class="checkin__form-body">
            <div class="custom-field">
              <label class="custom-field__label" v-if="appointmentIdentifierLabel">
                {{ appointmentIdentifierLabel }}
              </label>
              <v-text-field
                color="primary"
                variant="outlined"
                data-testid="comet-appointment-identifier"
                v-model="identifier"
                @focus="scrollIntoView"
                hide-details></v-text-field>
            </div>

            <div class="custom-field">
              <label class="custom-field__label">Notes</label>
              <v-textarea
                color="primary"
                variant="outlined"
                data-testid="comet-appointment-notes"
                v-model="notes"
                placeholder="Any special instructions or notes to the facility?"
                clearable
                hide-details></v-textarea>
            </div>

            <!-- These come from Check-in: Appointment Details Form for Driver -->
            <custom-fields
              :warehouse="warehouse"
              v-if="checkInFieldsExist"
              :value="checkInFields"></custom-fields>
          </div>
        </div>
      </template>
    </div>

    <div class="checkin__button-container">
      <v-btn
        v-if="currentStep === 3"
        block
        prepend-icon="mdi-check"
        :color="!isStepValid ? 'none' : 'primary'"
        :disabled="!isStepValid"
        :loading="submitting"
        @click="submit">
        Complete check-in
      </v-btn>

      <v-btn
        v-else
        block
        append-icon="mdi-arrow-right"
        :color="!isStepValid ? 'none' : 'primary'"
        :disabled="!isStepValid"
        @click="handleNext">
        Next
      </v-btn>
    </div>
  </v-form>
</template>

<script lang="ts">
import { AssetVisitType, AssetContainerType } from '@nova/core';
import { defineComponent, ref } from 'vue';
import type { VForm } from 'vuetify/components';
import { get, trim } from 'lodash';
import axios from 'axios';
import type { PropType } from 'vue';

import {
  clearLocalStorageKey,
  updateObjectInLocalStorage
} from '@/components/mixins/localStorageMixin';
import { useApiErrors, useCheckinForms } from '@/components/composables';
import AssetDetailsForm from './AssetDetailsForm.vue';
import type { IWarehouse, IAppointment } from '@nova/core';

const MAX_CONTAINER_DATA = 3;

export default defineComponent({
  name: 'AppointmentForm',
  components: {
    AssetDetailsForm
  },
  props: {
    latitude: {
      type: String,
      required: true
    },
    longitude: {
      type: String,
      required: true
    },
    warehouse: {
      type: Object as PropType<IWarehouse>,
      required: true
    }
  },
  emits: ['back'],
  setup(props) {
    const { makeErrorScreenParams } = useApiErrors();
    const {
      assetDetailsFields,
      additionalAssetDetailsFields,
      checkInFields,
      checkInFieldsExist,
      additionalAssetDetailsFieldsExist,
      checkInTriggers,
      additionalAssetDetailsTriggers,
      areRequiredAssetDetailsFilled,
      areRequiredCheckinFieldsFilled,
      setRequiredAssetVisitFields,
      extractFieldsFromTriggers,
      getAssetDetailsData,
      prepareCustomFormsData
    } = useCheckinForms(props.warehouse);

    const currentStep = ref(1);
    const isStepValid = ref(false);
    const assetVisitType = ref(AssetVisitType.Live);
    const assetContainerData = ref([
      { containerCode: '', containerType: AssetContainerType.Trailer }
    ]);

    const assetVisitTypes = ref([
      {
        value: AssetVisitType.Live,
        label: 'Live Load/Unload',
        helperText: 'I am loading or unloading my truck (FTL or LTL) while remaining on site.'
      },
      {
        value: AssetVisitType.Drop,
        label: 'Drop Only',
        helperText: 'I am leaving an asset (trailer, container, oversized, etc) at the facility.'
      },
      {
        value: AssetVisitType.PickUp,
        label: 'Pickup Only',
        helperText:
          'I am picking up an asset (trailer, container, oversized, etc) from the facility.'
      },
      {
        value: AssetVisitType.DropHook,
        label: 'Drop & Hook',
        helperText:
          'I am dropping off an asset (trailer, container, oversized, etc) and picking up another.'
      }
    ]);

    const assetContainerTypes = [
      { title: 'Trailer', value: AssetContainerType.Trailer },
      { title: 'Container', value: AssetContainerType.Container },
      { title: 'Oversized', value: AssetContainerType.Oversized },
      { title: 'Other', value: AssetContainerType.Other }
    ];

    const requiredValidator = (value: string, label: string) =>
      !value || value?.length === 0 ? `${label} is required` : true;

    return {
      additionalAssetDetailsFields,
      additionalAssetDetailsFieldsExist,
      additionalAssetDetailsTriggers,
      areRequiredAssetDetailsFilled,
      areRequiredCheckinFieldsFilled,
      assetContainerData,
      AssetContainerType,
      assetContainerTypes,
      assetDetailsFields,
      assetVisitType,
      assetVisitTypes,
      checkInFields,
      checkInFieldsExist,
      checkInTriggers,
      currentStep,
      isStepValid,
      MAX_CONTAINER_DATA,
      requiredValidator,
      extractFieldsFromTriggers,
      getAssetDetailsData,
      makeErrorScreenParams,
      prepareCustomFormsData,
      setRequiredAssetVisitFields
    };
  },
  data() {
    return {
      submitting: false,
      notes: '',
      identifier: '',
      appointment: {} as IAppointment,
      inlineError: {
        msg: '',
        icon: ''
      }
    };
  },
  computed: {
    appointmentIdentifierLabel() {
      const referenceDisplayName =
        this.warehouse.settings?.referenceNumberDisplayName ?? 'PO Number';
      return `${referenceDisplayName} or Confirmation Number`;
    },
    phoneNumber() {
      return this.assetDetailsFields?.find(({ key }) => key === 'phone')?.value;
    }
  },
  methods: {
    scrollIntoView($e: FocusEvent) {
      const targetElement = $e.target as Element;
      targetElement?.scrollIntoView({ behavior: 'smooth' });
    },
    setCustomFields() {
      this.checkInFields = this.extractFieldsFromTriggers(this.checkInTriggers);
      this.additionalAssetDetailsFields = this.extractFieldsFromTriggers(
        this.additionalAssetDetailsTriggers
      );
    },
    handleBack() {
      if (this.currentStep <= 1) {
        this.$emit('back');
      } else if (this.currentStep === 3 && this.assetVisitType === AssetVisitType.PickUp) {
        this.currentStep = 1;
      } else {
        this.currentStep--;
      }
    },
    handleNext() {
      if (this.currentStep === 1 && this.assetVisitType === AssetVisitType.PickUp) {
        this.currentStep = 3;
      } else {
        this.currentStep++;
      }
    },
    async submit() {
      this.inlineError.msg = '';

      this.submitting = true;

      const warehouseId = this.warehouse?.id || 'N/A';
      const warehouseName = this.warehouse?.name || 'N/A';
      const orgId = this.warehouse?.orgId || 'N/A';
      const orgName = this.warehouse?.org?.name || 'N/A';

      try {
        if (this.identifier) {
          await this.checkApptExists();
        }

        const assetDetails = this.getAssetDetailsData();
        const appointmentId = this.appointment?.id;
        const payload = {
          latitude: this.latitude,
          longitude: this.longitude,
          appointmentId: appointmentId || null,
          assetDetails: {
            ...assetDetails,
            assetVisitType: this.assetVisitType
          },
          customFormsData: this.prepareCustomFormsData(),
          warehouseId: this.warehouse.id,
          notes: this.notes?.replace(/\n/g, '<br />'),
          assetContainerDetails: this.assetContainerData
        };

        let data = null;
        if (appointmentId) {
          data = await this.submitPlanned(payload);
        } else {
          data = await this.submitUnplanned(payload);
        }

        if (data) {
          const assetVisit = get(data, 'appointment.assetVisit') ?? get(data, 'assetVisit');
          const appointment = get(data, 'appointment');
          const customFormsData = get(data, 'customFormsData') ?? [];
          const firstCustomFormTrigger = get(data, 'customFormsData.0.trigger');

          this.mixpanel.track('Completed: Self Check-in', {
            'Appointment ID': appointmentId || 'N/A',
            'Warehouse ID': warehouseId,
            'Warehouse Name': warehouseName,
            'Org ID': orgId,
            'Org Name': orgName,
            'Appointment Validation #':
              appointment?.refNumber || appointment?.confirmationNumber || 'N/A',
            'Asset Visit Type': assetVisit?.visitType || 'N/A',
            'Asset Visit ID': assetVisit?.id || 'N/A',
            'Entry Point': 'Self Check-in',
            'Check-in Type': appointmentId ? 'Planned Arrival' : 'Unplanned Arrival'
          });

          if (Array.isArray(customFormsData) && customFormsData.length > 0) {
            this.mixpanel.track('Submitted: Custom Form', {
              'Custom Form Application': firstCustomFormTrigger?.app,
              'Custom Form Type': firstCustomFormTrigger?.feature,
              'Custom Form Trigger': firstCustomFormTrigger?.category,
              'Appointment ID': appointmentId || 'N/A',
              'Warehouse ID': warehouseId,
              'Warehouse Name': warehouseName,
              'Org ID': orgId,
              'Org Name': orgName,
              'Entry Point': 'Self Check-in'
            });
          }
        }
        this.$router.push({
          name: 'completed',
          params: data.initialMessageFailed
            ? {
                errorTitle: 'Unable to send SMS message',
                errorMessage: `We couldn't send a messsage to ${
                  this.phoneNumber || 'the provided phone number'
                }. <br /><br />
                 If you have previously unsubscribed, please text START to
                 <a href='sms:${import.meta.env.VITE_SENDER_PHONE_NUMBER}'>${
                  import.meta.env.VITE_SENDER_PHONE_NUMBER
                }</a> to start receiving
                 SMS messages from this warehouse.`
              }
            : {}
        });
      } catch (err) {
        if (axios.isAxiosError(err)) {
          const errorParams = this.makeErrorScreenParams(err);

          this.mixpanel.track('Error: Self Check-in', {
            'Appointment Validation #':
              this.appointment?.refNumber ||
              this.appointment?.confirmationNumber ||
              this.identifier ||
              'N/A',
            'Warehouse ID': warehouseId,
            'Warehouse Name': warehouseName,
            'Org ID': orgId,
            'Org Name': orgName,
            Latitude: this.latitude || 'N/A',
            Longitude: this.longitude || 'N/A',
            'Error Type': errorParams.title,
            'Check-in Type': this.appointment?.id ? 'Planned Arrival' : 'Unplanned Arrival'
          });

          if (errorParams.showErrorInline !== '1') {
            this.$router.push({
              name: 'appointment-error',
              params: errorParams
            });
          } else {
            this.inlineError.msg = errorParams.title;
            this.inlineError.icon = errorParams.icon;
          }
        }
      } finally {
        this.submitting = false;
      }
    },
    async checkApptExists() {
      try {
        const { data } = await this.axiosClient.get(
          `/checkin/public/${encodeURIComponent(this.identifier)}/${this.warehouse.id}/?latitude=${
            this.latitude
          }&longitude=${this.longitude}`
        );

        this.appointment = { ...data.data };
        updateObjectInLocalStorage('comet:appointment', this.appointment);
      } catch (error) {
        this.appointment = {} as IAppointment;
        clearLocalStorageKey('comet:appointment');

        const status = get(error, 'response.status');

        /**
         * If the appointment is not found (error 404), we want to continue with the
         * submit process, so we handle it as unplanned.
         * However, if the appointment is not valid for whatever reason (error 400), we want
         * to throw the error so the user can see the error message.
         */

        if (status !== 404) {
          throw error;
        }
      }
    },
    async submitUnplanned(payload: Record<string, unknown>) {
      const { data } = await this.axiosClient.put(
        `/checkin/public/${this.warehouse.id}/unplanned`,
        payload
      );
      updateObjectInLocalStorage('comet:misc', { isPlanned: false });
      return data;
    },
    async submitPlanned(payload: Record<string, unknown>) {
      const { data } = await this.axiosClient.put(`/checkin/public/${this.warehouse.id}`, payload);
      updateObjectInLocalStorage('comet:misc', { isPlanned: true });
      return data;
    },
    async validateStep() {
      if (this.currentStep === 1) {
        this.isStepValid = true;
      } else if (this.currentStep === 2) {
        this.isStepValid = this.assetContainerData.every(
          ({ containerCode, containerType }) =>
            trim(containerCode).length > 0 && trim(containerType).length > 0
        );
      } else if (this.currentStep === 3) {
        const areRequiredFieldsFilled =
          this.areRequiredAssetDetailsFilled && this.areRequiredCheckinFieldsFilled;

        if (areRequiredFieldsFilled) {
          const validateResult = await (this.$refs.appointmentForm as VForm).validate();

          this.isStepValid = Boolean(areRequiredFieldsFilled && validateResult?.valid);
        } else {
          this.isStepValid = areRequiredFieldsFilled;
        }
      } else {
        this.isStepValid = false;
      }
    }
  },
  mounted() {
    this.setCustomFields();
    this.setRequiredAssetVisitFields();
  },
  watch: {
    warehouse() {
      this.setCustomFields();
      this.setRequiredAssetVisitFields();
    },
    currentStep: {
      immediate: true,
      handler() {
        this.validateStep();

        this.$nextTick(() => {
          const appointmentForm = document.getElementById('appointment-form');
          if (!appointmentForm) {
            return;
          }

          /**
           * This is a nice-to-have just to improve the UX when the user is on step 2 or 3,
           * by focusing the first input of the form.
           */

          if (this.currentStep === 2) {
            // here we are focusing the code input, instead of the asset type
            const firstInputWrapper = appointmentForm.querySelector(
              '[data-testid="asset-visit-form-container-code-0"]'
            );
            const firstInput = firstInputWrapper?.querySelector('input');

            if (firstInput) {
              firstInput.focus();
            }

            return;
          }

          if (this.currentStep === 3) {
            const firstInput = appointmentForm.querySelector('input');

            if (firstInput) {
              firstInput.focus();
            }
          }

          appointmentForm.scrollIntoView({ behavior: 'smooth' });
        });
      }
    },
    assetContainerData: {
      deep: true,
      handler() {
        this.validateStep();
      }
    },
    notes: {
      handler() {
        if (this.currentStep === 3) {
          this.validateStep();
        }
      }
    },
    identifier: {
      handler() {
        if (this.currentStep === 3) {
          this.validateStep();
        }
      }
    },
    assetDetailsFields: {
      deep: true,
      handler() {
        if (this.currentStep === 3) {
          this.validateStep();
        }
      }
    },
    additionalAssetDetailsFields: {
      deep: true,
      handler() {
        if (this.currentStep === 3) {
          this.validateStep();
        }
      }
    },
    checkInFields: {
      deep: true,
      handler() {
        if (this.currentStep === 3) {
          this.validateStep();
        }
      }
    },
    areRequiredAssetDetailsFilled() {
      if (this.currentStep === 3) {
        this.validateStep();
      }
    },
    areRequiredCheckinFieldsFilled() {
      if (this.currentStep === 3) {
        this.validateStep();
      }
    }
  }
});
</script>

<style lang="scss">
.checkin__form-container {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.checkin__form-header {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.checkin__form-body {
  display: flex;
  flex-direction: column;
  gap: 16px;

  border-radius: 4px;
  border: 1px solid #ebebeb;
  background-color: #fff;

  padding: 16px;
}

.checkin__button-container {
  position: fixed;
  inset-inline: 0;
  bottom: 0;
  background: white;
  border-top: 1px solid #ebebeb;
  padding: $spacing-4;
}
</style>
