<template>
  <div class="custom-field">
    <label class="custom-field__label" :class="{ required: field.required }" v-if="field.label">
      {{ field.label }}
    </label>

    <v-textarea
      hide-details="auto"
      color="primary"
      variant="outlined"
      :required="field.required"
      :class="{ required: field.required }"
      v-model="value"
      clearable
      :title="field.description"
      :placeholder="field.placeholder ?? field.label"
      :persistent-placeholder="Boolean(field.placeholder)"
      :rules="validationRules"></v-textarea>
  </div>
</template>

<script>
import customFieldTypeMixin from '@/components/custom_fields/mixins/customFieldTypeMixin';

export default {
  mixins: [customFieldTypeMixin]
};
</script>
