<template>
  <div class="custom-field">
    <label class="custom-field__label" :class="{ required: field.required }" v-if="field.label">
      {{ field.label }}
    </label>
    <v-autocomplete
      color="primary"
      variant="outlined"
      v-model="field.value"
      :rules="validationRules"
      :items="field.dropDownValues"
      @update:model-value="value => $emit('input', value || '')"
      :placeholder="field.description"
      clearable
      :menu-props="{ maxHeight }"
      open-on-clear></v-autocomplete>
  </div>
</template>

<script>
import customFieldTypeMixin from '@/components/custom_fields/mixins/customFieldTypeMixin';
import { isMobile } from '@/util';

export default {
  name: 'custom-field-dropdown',
  mixins: [customFieldTypeMixin],
  computed: {
    maxHeight() {
      return isMobile() ? 150 : 500;
    }
  }
};
</script>
