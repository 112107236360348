<template>
  <div class="d-flex flex-row align-center justify-space-between">
    <logo :primary="primary"></logo>
    <slot></slot>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
export default defineComponent({
  props: {
    primary: {
      type: Boolean,
      default: false
    }
  }
});
</script>
