<template>
  <div class="checkin__form-container">
    <div class="checkin__form-header">
      <h2 class="typography-heading-md-bold">Asset Details</h2>
      <h3 class="typography-body-sm text__color-text-tertiary">
        Please provide more details about the asset attached to your truck.
      </h3>
    </div>

    <div class="border d-flex justify-center pa-4">
      You Selected:&nbsp;
      <strong>{{ assetVisitTypeLabel }} visit</strong>
    </div>

    <div class="checkin__form-body" v-for="(_, i) of assetContainerData" :key="i">
      <div class="custom-field">
        <v-label class="custom-field__label required">Asset Type</v-label>
        <v-select
          variant="outlined"
          color="primary"
          placeholder="Select the Asset type"
          :id="`asset-visit-form-container-type-${i}`"
          v-model="assetContainerData[i].containerType"
          :rules="[requiredValidator(assetContainerData[i].containerType, 'Trailer type')]"
          :data-testid="`asset-visit-form-container-type-${i}`"
          :items="assetContainerTypes"
          item-title="title"
          item-value="value"
          class="flex-grow-1"
          hide-details></v-select>
      </div>

      <div class="custom-field">
        <v-label class="custom-field__label required">Asset ID</v-label>
        <v-text-field
          color="primary"
          variant="outlined"
          placeholder="E.g: Trailer number, Container ID, etc"
          :data-testid="`asset-visit-form-container-code-${i}`"
          v-model="assetContainerData[i].containerCode"
          maxlength="50"
          required
          :rules="[requiredValidator(assetContainerData[i].containerCode, 'Trailer ID')]"
          class="flex-grow-1"
          hide-details></v-text-field>
      </div>
      <v-btn
        v-if="assetContainerData.length > 1 && i > 0"
        variant="text"
        color="error"
        click="assetContainerData"
        @click="handleRemove(i)"
        style="align-self: flex-end">
        Remove
      </v-btn>
    </div>
    <v-btn
      block
      variant="text"
      style="align-self: flex-end; margin-top: -12px"
      v-if="assetContainerData.length < maxContainerData"
      @click="handleAdd"
      prepend-icon="mdi-plus">
      Add another asset
    </v-btn>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, nextTick } from 'vue';
import { AssetContainerType } from '@nova/core';

export default defineComponent({
  name: 'AssetDetailsForm',
  props: {
    assetVisitType: {
      type: String,
      required: true
    },
    assetVisitTypes: {
      type: Array as () => Array<{ value: string; label: string }>,
      required: true
    },
    assetContainerData: {
      type: Array as () => Array<{ containerCode: string; containerType: AssetContainerType }>,
      required: true
    },
    assetContainerTypes: {
      type: Array as () => Array<{ title: string; value: AssetContainerType }>,
      required: true
    },
    maxContainerData: {
      type: Number,
      required: true
    }
  },
  emits: ['update:assetContainerData'],
  setup(props, { emit }) {
    const assetVisitTypeLabel = computed(
      () => props.assetVisitTypes.find(v => v.value === props.assetVisitType)?.label
    );

    const requiredValidator = (value: string, label: string) =>
      !value || value?.length === 0 ? `${label} is required` : true;

    const handleRemove = (index: number) => {
      const newData = [...props.assetContainerData];
      newData.splice(index, 1);
      emit('update:assetContainerData', newData);
    };

    const handleAdd = () => {
      const newData = [
        ...props.assetContainerData,
        {
          containerCode: '',
          containerType: AssetContainerType.Trailer
        }
      ];

      emit('update:assetContainerData', newData);

      /**
       * This is a nice-to-have just to improve the UX when the user
       * adds a new asset, so we focus the asset type input.
       */
      nextTick(() => {
        const lastContainerType = document.getElementById(
          `asset-visit-form-container-type-${newData.length - 1}`
        );

        if (lastContainerType) {
          lastContainerType.focus();
          lastContainerType.scrollIntoView({ behavior: 'smooth' });
        }
      });
    };

    return {
      assetVisitTypeLabel,
      requiredValidator,
      handleRemove,
      handleAdd
    };
  }
});
</script>
