/**
 * Calculates the greatest common divisor (GCD) of the given numbers.
 *
 * @param {...number[]} nums - An array of numbers to find the GCD of.
 * @returns {number} The greatest common divisor of the given numbers. Returns 0 if no numbers are provided.
 *
 * @example
 * // Returns 6
 * greatestCommonDiv(54, 24);
 *
 * @example
 * // Returns 1
 * greatestCommonDiv(17, 13);
 */
export function greatestCommonDiv(...nums: number[]): number {
  if (nums.length === 0) {
    return 0;
  }
  const gcd = (a: number, b: number): number => {
    [a, b] = [Math.abs(a), Math.abs(b)];
    while (b !== 0) {
      [a, b] = [b, a % b];
    }
    return a;
  };
  return nums.reduce((acc, num) => gcd(acc, num));
}
