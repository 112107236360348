<template>
  <div class="wrapper d-flex flex-column gap-2" :class="{ light: light }">
    <p class="typography-heading-sm-bold">
      Confirmation Number {{ appointment.confirmationNumber }}
    </p>
    <p class="typography-body-sm">{{ appointmentDateTimeHeader }}</p>
    <p class="typography-body-sm">{{ appointment.user?.company?.name }}</p>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { formatDateTimeWithMilitarySupport, LuxonDateTimeFormats } from '@nova/core';

export default defineComponent({
  props: {
    appointment: {
      type: Object,
      default: () => ({})
    },
    light: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    appointmentDateTimeHeader() {
      const date = this.formatDateTime(
        this.appointment.start,
        LuxonDateTimeFormats.MonthDayYearSlashed,
        LuxonDateTimeFormats.MonthDayYearSlashed
      );
      const startTime = this.formatDateTime(
        this.appointment.start,
        LuxonDateTimeFormats.Extended12HrTimeAMPM,
        LuxonDateTimeFormats.Extended24HrTime
      );
      const endTime = this.formatDateTime(
        this.appointment.end,
        LuxonDateTimeFormats.Extended12HrTimeAMPM,
        LuxonDateTimeFormats.Extended24HrTime
      );
      return `${date} from ${startTime} to ${endTime}`;
    }
  },
  methods: {
    formatDateTime(
      dateTime: string,
      format12h: LuxonDateTimeFormats,
      format24h: LuxonDateTimeFormats
    ): string {
      return formatDateTimeWithMilitarySupport(
        dateTime,
        this.appointment.dock.warehouse.timezone,
        format12h,
        this.$store.getters.isMilitaryTimeEnabled,
        format24h
      ).toUpperCase();
    }
  }
});
</script>

<style scoped>
.wrapper {
  border-radius: 8px;
  color: #1e3036;
  background: rgba(188, 206, 212, 0.2);
}

.light {
  color: #f8fbfc;
  background: none;
}
</style>
