import { CAPACITY_CHILD_SLUG } from './dock';

export const mockWarehouses = [
  {
    id: '932cbe67-7af1-4f1c-9d38-81bd16f23e02',
    createDateTime: '2023-04-21T04:10:40.955Z',
    createdBy: '504037bf-26b1-4f61-9907-bc2a6cc16ba2',
    lastChangedDateTime: '2023-04-21T04:10:51.009Z',
    lastChangedBy: '504037bf-26b1-4f61-9907-bc2a6cc16ba2',
    isActive: true,
    tags: null,
    schedule: {
      friday: [{ end: '17:00', start: '8:00' }],
      monday: [{ end: '17:00', start: '8:00' }],
      tuesday: [{ end: '17:00', start: '8:00' }],
      version: 1,
      thursday: [{ end: '17:00', start: '8:00' }],
      wednesday: [{ end: '17:00', start: '8:00' }]
    },
    name: 'New York',
    facilityNumber: '110',
    street: '459 Broadway',
    city: 'New York',
    state: 'NY',
    zip: '10013',
    country: 'USA',
    timezone: 'America/New_York',
    orgId: 'fc8a34b6-06f4-406c-853a-942dd8425a31',
    contactName: null,
    phone: null,
    email: null,
    notes: null,
    instructions: null,
    customApptFieldsTemplate: null,
    refNumberValidationVersion: 'V2',
    refNumberValidationUrl: null,
    refNumberValidationPasscode: null,
    settings: {
      appointmentStartTimeMinutes: 30,
      customAppointmentStartTimes: [],
      isLoadTypeDurationCarrierViewHidden: false
    },
    allowCarrierScheduling: true,
    geolocation: null,
    ccEmails: [],
    amenities: [],
    ppeRequirements: [],
    docks: [
      {
        id: 'c7522cbd-f4f3-443c-b02b-f794b7e7bdd9',
        createDateTime: '2023-04-21T14:43:37.538Z',
        createdBy: '504037bf-26b1-4f61-9907-bc2a6cc16ba2',
        lastChangedDateTime: '2023-04-21T21:45:02.494Z',
        lastChangedBy: '504037bf-26b1-4f61-9907-bc2a6cc16ba2',
        isActive: true,
        tags: null,
        schedule: {
          friday: [
            { end: '11:30', start: '0:00' },
            { end: '13:30', start: '12:00' },
            { end: '15:30', start: '14:00' },
            { end: '23:59', start: '16:00' }
          ],
          monday: [
            { end: '11:30', start: '0:00' },
            { end: '13:30', start: '12:00' },
            { end: '15:30', start: '14:00' },
            { end: '23:59', start: '16:00' }
          ],
          sunday: [
            { end: '11:30', start: '0:00' },
            { end: '13:30', start: '12:00' },
            { end: '15:30', start: '14:00' },
            { end: '23:59', start: '16:00' }
          ],
          tuesday: [
            { end: '11:30', start: '0:00' },
            { end: '13:30', start: '12:00' },
            { end: '15:30', start: '14:00' },
            { end: '23:59', start: '16:00' }
          ],
          version: 1,
          saturday: [
            { end: '11:30', start: '0:00' },
            { end: '13:30', start: '12:00' },
            { end: '15:30', start: '14:00' },
            { end: '23:59', start: '16:00' }
          ],
          thursday: [
            { end: '11:30', start: '0:00' },
            { end: '13:30', start: '12:00' },
            { end: '15:30', start: '14:00' },
            { end: '23:59', start: '16:00' }
          ],
          wednesday: [
            { end: '11:30', start: '0:00' },
            { end: '13:30', start: '12:00' },
            { end: '15:30', start: '14:00' },
            { end: '23:59', start: '16:00' }
          ],
          closedIntervals: [
            { end: '2023-04-26T23:59:59-04:00', start: '2023-04-26T00:00:00-04:00' },
            { end: '2023-06-19T23:59:59-04:00', start: '2023-06-19T00:00:00-04:00' }
          ]
        },
        name: 'test',
        warehouseId: '932cbe67-7af1-4f1c-9d38-81bd16f23e02',
        orgId: 'fc8a34b6-06f4-406c-853a-942dd8425a31',
        loadTypeIds: ['9ff991a6-769b-4bcc-a873-978523339152'],
        doorNumber: null,
        instructions: null,
        minCarrierLeadTime_hr: null,
        minCarrierLeadTimeForUpdates_hr: null,
        maxCarrierLeadTime_hr: null,
        ccEmails: null,
        allowCarrierScheduling: true,
        allowOverBooking: false,
        capacityParentId: null,
        sortOrder: 2,
        settings: null,
        capacityChildren: []
      },
      {
        id: '51bad233-48d5-4a42-a26e-dd3994bca619',
        createDateTime: '2023-04-21T04:11:40.742Z',
        createdBy: '504037bf-26b1-4f61-9907-bc2a6cc16ba2',
        lastChangedDateTime: '2023-04-21T21:43:38.861Z',
        lastChangedBy: '504037bf-26b1-4f61-9907-bc2a6cc16ba2',
        isActive: true,
        tags: null,
        schedule: {
          friday: [
            { end: '11:30', start: '0:00' },
            { end: '13:30', start: '12:00' },
            { end: '15:30', start: '14:00' },
            { end: '23:59', start: '16:00' }
          ],
          monday: [
            { end: '11:30', start: '0:00' },
            { end: '13:30', start: '12:00' },
            { end: '15:30', start: '14:00' },
            { end: '23:59', start: '16:00' }
          ],
          sunday: [
            { end: '11:30', start: '0:00' },
            { end: '13:30', start: '12:00' },
            { end: '15:30', start: '14:00' },
            { end: '23:59', start: '16:00' }
          ],
          tuesday: [
            { end: '11:30', start: '0:00' },
            { end: '13:30', start: '12:00' },
            { end: '15:30', start: '14:00' },
            { end: '23:59', start: '16:00' }
          ],
          version: 1,
          saturday: [
            { end: '11:30', start: '0:00' },
            { end: '13:30', start: '12:00' },
            { end: '15:30', start: '14:00' },
            { end: '23:59', start: '16:00' }
          ],
          thursday: [
            { end: '11:30', start: '0:00' },
            { end: '13:30', start: '12:00' },
            { end: '15:30', start: '14:00' },
            { end: '23:59', start: '16:00' }
          ],
          wednesday: [
            { end: '11:30', start: '0:00' },
            { end: '13:30', start: '12:00' },
            { end: '15:30', start: '14:00' },
            { end: '23:59', start: '16:00' }
          ],
          closedIntervals: [
            { end: '2023-05-14T23:59:59-04:00', start: '2023-05-14T00:00:00-04:00' }
          ]
        },
        name: 'Reefer',
        warehouseId: '932cbe67-7af1-4f1c-9d38-81bd16f23e02',
        orgId: 'fc8a34b6-06f4-406c-853a-942dd8425a31',
        loadTypeIds: ['9ff991a6-769b-4bcc-a873-978523339152'],
        doorNumber: '5',
        instructions: null,
        minCarrierLeadTime_hr: null,
        minCarrierLeadTimeForUpdates_hr: null,
        maxCarrierLeadTime_hr: null,
        ccEmails: null,
        allowCarrierScheduling: true,
        allowOverBooking: false,
        capacityParentId: '51bad233-48d5-4a42-a26e-dd3994bca619',
        sortOrder: 0,
        settings: null,
        capacityChildren: [
          {
            id: '83e4e525-bd67-42f4-9d89-777b7a3d15ae',
            createDateTime: '2023-04-21T14:42:52.459Z',
            createdBy: '504037bf-26b1-4f61-9907-bc2a6cc16ba2',
            lastChangedDateTime: '2023-04-21T21:43:38.879Z',
            lastChangedBy: '504037bf-26b1-4f61-9907-bc2a6cc16ba2',
            isActive: true,
            tags: null,
            schedule: {
              friday: [
                { end: '11:30', start: '0:00' },
                { end: '13:30', start: '12:00' },
                { end: '15:30', start: '14:00' },
                { end: '23:59', start: '16:00' }
              ],
              monday: [
                { end: '11:30', start: '0:00' },
                { end: '13:30', start: '12:00' },
                { end: '15:30', start: '14:00' },
                { end: '23:59', start: '16:00' }
              ],
              sunday: [
                { end: '11:30', start: '0:00' },
                { end: '13:30', start: '12:00' },
                { end: '15:30', start: '14:00' },
                { end: '23:59', start: '16:00' }
              ],
              tuesday: [
                { end: '11:30', start: '0:00' },
                { end: '13:30', start: '12:00' },
                { end: '15:30', start: '14:00' },
                { end: '23:59', start: '16:00' }
              ],
              version: 1,
              saturday: [
                { end: '11:30', start: '0:00' },
                { end: '13:30', start: '12:00' },
                { end: '15:30', start: '14:00' },
                { end: '23:59', start: '16:00' }
              ],
              thursday: [
                { end: '11:30', start: '0:00' },
                { end: '13:30', start: '12:00' },
                { end: '15:30', start: '14:00' },
                { end: '23:59', start: '16:00' }
              ],
              wednesday: [{ end: '23:59', start: '0:00' }]
            },
            name: `Reefer${CAPACITY_CHILD_SLUG}1682088172450`,
            warehouseId: '932cbe67-7af1-4f1c-9d38-81bd16f23e02',
            orgId: 'fc8a34b6-06f4-406c-853a-942dd8425a31',
            loadTypeIds: ['9ff991a6-769b-4bcc-a873-978523339152'],
            doorNumber: '5',
            instructions: null,
            minCarrierLeadTime_hr: null,
            minCarrierLeadTimeForUpdates_hr: null,
            maxCarrierLeadTime_hr: null,
            ccEmails: null,
            allowCarrierScheduling: true,
            allowOverBooking: false,
            capacityParentId: '51bad233-48d5-4a42-a26e-dd3994bca619',
            sortOrder: 1,
            settings: null
          },
          {
            id: '51bad233-48d5-4a42-a26e-dd3994bca619',
            createDateTime: '2023-04-21T04:11:40.742Z',
            createdBy: '504037bf-26b1-4f61-9907-bc2a6cc16ba2',
            lastChangedDateTime: '2023-04-21T21:43:38.861Z',
            lastChangedBy: '504037bf-26b1-4f61-9907-bc2a6cc16ba2',
            isActive: true,
            tags: null,
            schedule: {
              friday: [
                { end: '11:30', start: '0:00' },
                { end: '13:30', start: '12:00' },
                { end: '15:30', start: '14:00' },
                { end: '23:59', start: '16:00' }
              ],
              monday: [
                { end: '11:30', start: '0:00' },
                { end: '13:30', start: '12:00' },
                { end: '15:30', start: '14:00' },
                { end: '23:59', start: '16:00' }
              ],
              sunday: [
                { end: '11:30', start: '0:00' },
                { end: '13:30', start: '12:00' },
                { end: '15:30', start: '14:00' },
                { end: '23:59', start: '16:00' }
              ],
              tuesday: [
                { end: '11:30', start: '0:00' },
                { end: '13:30', start: '12:00' },
                { end: '15:30', start: '14:00' },
                { end: '23:59', start: '16:00' }
              ],
              version: 1,
              saturday: [
                { end: '11:30', start: '0:00' },
                { end: '13:30', start: '12:00' },
                { end: '15:30', start: '14:00' },
                { end: '23:59', start: '16:00' }
              ],
              thursday: [
                { end: '11:30', start: '0:00' },
                { end: '13:30', start: '12:00' },
                { end: '15:30', start: '14:00' },
                { end: '23:59', start: '16:00' }
              ],
              wednesday: [
                { end: '11:30', start: '0:00' },
                { end: '13:30', start: '12:00' },
                { end: '15:30', start: '14:00' },
                { end: '23:59', start: '16:00' }
              ],
              closedIntervals: [
                { end: '2023-05-14T23:59:59-04:00', start: '2023-05-14T00:00:00-04:00' }
              ]
            },
            name: 'Reefer',
            warehouseId: '932cbe67-7af1-4f1c-9d38-81bd16f23e02',
            orgId: 'fc8a34b6-06f4-406c-853a-942dd8425a31',
            loadTypeIds: ['9ff991a6-769b-4bcc-a873-978523339152'],
            doorNumber: '5',
            instructions: null,
            minCarrierLeadTime_hr: null,
            minCarrierLeadTimeForUpdates_hr: null,
            maxCarrierLeadTime_hr: null,
            ccEmails: null,
            allowCarrierScheduling: true,
            allowOverBooking: false,
            capacityParentId: '51bad233-48d5-4a42-a26e-dd3994bca619',
            sortOrder: 0,
            settings: null
          }
        ]
      },
      {
        id: '83e4e525-bd67-42f4-9d89-777b7a3d15ae',
        createDateTime: '2023-04-21T14:42:52.459Z',
        createdBy: '504037bf-26b1-4f61-9907-bc2a6cc16ba2',
        lastChangedDateTime: '2023-04-21T21:43:38.879Z',
        lastChangedBy: '504037bf-26b1-4f61-9907-bc2a6cc16ba2',
        isActive: true,
        tags: null,
        schedule: {
          friday: [
            { end: '11:30', start: '0:00' },
            { end: '13:30', start: '12:00' },
            { end: '15:30', start: '14:00' },
            { end: '23:59', start: '16:00' }
          ],
          monday: [
            { end: '11:30', start: '0:00' },
            { end: '13:30', start: '12:00' },
            { end: '15:30', start: '14:00' },
            { end: '23:59', start: '16:00' }
          ],
          sunday: [
            { end: '11:30', start: '0:00' },
            { end: '13:30', start: '12:00' },
            { end: '15:30', start: '14:00' },
            { end: '23:59', start: '16:00' }
          ],
          tuesday: [
            { end: '11:30', start: '0:00' },
            { end: '13:30', start: '12:00' },
            { end: '15:30', start: '14:00' },
            { end: '23:59', start: '16:00' }
          ],
          version: 1,
          saturday: [
            { end: '11:30', start: '0:00' },
            { end: '13:30', start: '12:00' },
            { end: '15:30', start: '14:00' },
            { end: '23:59', start: '16:00' }
          ],
          thursday: [
            { end: '11:30', start: '0:00' },
            { end: '13:30', start: '12:00' },
            { end: '15:30', start: '14:00' },
            { end: '23:59', start: '16:00' }
          ],
          wednesday: [{ end: '23:59', start: '0:00' }]
        },
        name: `Reefer${CAPACITY_CHILD_SLUG}1682088172450`,
        warehouseId: '932cbe67-7af1-4f1c-9d38-81bd16f23e02',
        orgId: 'fc8a34b6-06f4-406c-853a-942dd8425a31',
        loadTypeIds: ['9ff991a6-769b-4bcc-a873-978523339152'],
        doorNumber: '5',
        instructions: null,
        minCarrierLeadTime_hr: null,
        minCarrierLeadTimeForUpdates_hr: null,
        maxCarrierLeadTime_hr: null,
        ccEmails: null,
        allowCarrierScheduling: true,
        allowOverBooking: false,
        capacityParentId: '51bad233-48d5-4a42-a26e-dd3994bca619',
        sortOrder: 1,
        settings: null,
        capacityChildren: []
      }
    ],
    org: {
      id: 'fc8a34b6-06f4-406c-853a-942dd8425a31',
      createDateTime: '2023-04-21T04:09:57.641Z',
      createdBy: '504037bf-26b1-4f61-9907-bc2a6cc16ba2',
      lastChangedDateTime: '2023-04-26T15:37:17.044Z',
      lastChangedBy: '504037bf-26b1-4f61-9907-bc2a6cc16ba2',
      isActive: true,
      tags: null,
      name: "Sam's Club",
      isDataWallEnabled: false,
      settings: {
        referenceNumberIsUnique: false,
        statusSucceedingArrived: 'InProgress',
        referenceNumberIsVisible: true,
        appointmentCreationStatus: 'Requested',
        referenceNumberIsRequired: false,
        appointmentStartTimeMinutes: 30,
        appointmentStartTimeInterval: '30',
        muteAppointmentNotifications: false,
        mutedAppointmentNotifications: []
      },
      favoriteCarrierIds: null,
      customTags: null,
      expiresAt: '2023-05-21T05:13:00.371Z'
    }
  },
  {
    id: '80d28081-e8d7-4691-8eb4-db5d7baa4d37',
    createDateTime: '2023-04-26T15:16:57.689Z',
    createdBy: '504037bf-26b1-4f61-9907-bc2a6cc16ba2',
    lastChangedDateTime: '2023-04-26T15:18:54.752Z',
    lastChangedBy: '504037bf-26b1-4f61-9907-bc2a6cc16ba2',
    isActive: true,
    tags: null,
    schedule: {
      friday: [],
      monday: [{ end: '13:00', start: '4:00' }],
      sunday: [{ end: '14:00', start: '3:00' }],
      tuesday: [{ end: '12:00', start: '4:00' }],
      version: 1,
      thursday: [{ end: '10:00', start: '4:00' }],
      wednesday: [{ end: '11:00', start: '3:00' }]
    },
    name: 'Schedule Test',
    facilityNumber: null,
    street: '459 Broadway',
    city: 'New York',
    state: 'NY',
    zip: '10013',
    country: 'USA',
    timezone: 'America/New_York',
    orgId: 'fc8a34b6-06f4-406c-853a-942dd8425a31',
    contactName: null,
    phone: null,
    email: null,
    notes: null,
    instructions: '',
    customApptFieldsTemplate: null,
    refNumberValidationVersion: 'V2',
    refNumberValidationUrl: null,
    refNumberValidationPasscode: null,
    settings: {
      appointmentStartTimeMinutes: 30,
      customAppointmentStartTimes: [],
      isLoadTypeDurationCarrierViewHidden: false
    },
    allowCarrierScheduling: true,
    geolocation: null,
    ccEmails: ['phaseflipaudio@gmail.com'],
    amenities: ['Lumper services', 'Drivers restroom', 'Overnight parking', 'Free Wi-Fi'],
    ppeRequirements: [
      'Face Mask',
      'Safety Glasses',
      'Hard Hat',
      'Safety Boots',
      'Gloves',
      'High Visibility Vest',
      'Long Pants',
      'Long Sleeves',
      'No Smoking'
    ],
    docks: [
      {
        id: 'd47bbcfa-fed7-4068-a814-4fead4cd6227',
        createDateTime: '2023-04-26T15:21:21.407Z',
        createdBy: '504037bf-26b1-4f61-9907-bc2a6cc16ba2',
        lastChangedDateTime: '2023-04-26T15:24:01.263Z',
        lastChangedBy: '504037bf-26b1-4f61-9907-bc2a6cc16ba2',
        isActive: true,
        tags: null,
        schedule: {
          friday: [{ end: '23:59', start: '0:00' }],
          monday: [{ end: '23:59', start: '0:00' }],
          sunday: [{ end: '23:59', start: '0:00' }],
          tuesday: [{ end: '23:59', start: '0:00' }],
          version: 1,
          saturday: [{ end: '23:59', start: '0:00' }],
          thursday: [{ end: '23:59', start: '0:00' }],
          wednesday: [
            { end: '3:00', start: '0:00' },
            { end: '23:59', start: '4:00' }
          ],
          closedIntervals: []
        },
        name: 'Inbound',
        warehouseId: '80d28081-e8d7-4691-8eb4-db5d7baa4d37',
        orgId: 'fc8a34b6-06f4-406c-853a-942dd8425a31',
        loadTypeIds: ['b4e12083-45b1-4a1b-9dd4-5fe91a49ba34'],
        doorNumber: null,
        instructions: null,
        minCarrierLeadTime_hr: null,
        minCarrierLeadTimeForUpdates_hr: null,
        maxCarrierLeadTime_hr: 8760,
        ccEmails: null,
        allowCarrierScheduling: true,
        allowOverBooking: false,
        capacityParentId: null,
        sortOrder: 0,
        settings: null,
        capacityChildren: []
      },
      {
        id: '6571f58a-1985-4c29-8e3b-276647652322',
        createDateTime: '2023-04-26T15:22:15.752Z',
        createdBy: '504037bf-26b1-4f61-9907-bc2a6cc16ba2',
        lastChangedDateTime: '2023-04-26T15:24:06.122Z',
        lastChangedBy: '504037bf-26b1-4f61-9907-bc2a6cc16ba2',
        isActive: true,
        tags: null,
        schedule: {
          friday: [{ end: '23:59', start: '0:00' }],
          monday: [{ end: '23:59', start: '0:00' }],
          sunday: [{ end: '23:59', start: '0:00' }],
          tuesday: [{ end: '23:59', start: '0:00' }],
          version: 1,
          saturday: [{ end: '23:59', start: '0:00' }],
          thursday: [
            { end: '4:00', start: '0:00' },
            { end: '23:59', start: '8:30' }
          ],
          wednesday: [
            { end: '3:00', start: '0:00' },
            { end: '23:59', start: '4:00' }
          ],
          closedIntervals: []
        },
        name: 'Outbound',
        warehouseId: '80d28081-e8d7-4691-8eb4-db5d7baa4d37',
        orgId: 'fc8a34b6-06f4-406c-853a-942dd8425a31',
        loadTypeIds: ['b4e12083-45b1-4a1b-9dd4-5fe91a49ba34'],
        doorNumber: null,
        instructions: null,
        minCarrierLeadTime_hr: null,
        minCarrierLeadTimeForUpdates_hr: null,
        maxCarrierLeadTime_hr: null,
        ccEmails: null,
        allowCarrierScheduling: true,
        allowOverBooking: false,
        capacityParentId: null,
        sortOrder: 1,
        settings: null,
        capacityChildren: []
      }
    ],
    org: {
      id: 'fc8a34b6-06f4-406c-853a-942dd8425a31',
      createDateTime: '2023-04-21T04:09:57.641Z',
      createdBy: '504037bf-26b1-4f61-9907-bc2a6cc16ba2',
      lastChangedDateTime: '2023-04-26T15:37:17.044Z',
      lastChangedBy: '504037bf-26b1-4f61-9907-bc2a6cc16ba2',
      isActive: true,
      tags: null,
      name: "Sam's Club",
      isDataWallEnabled: false,
      settings: {
        referenceNumberIsUnique: false,
        statusSucceedingArrived: 'InProgress',
        referenceNumberIsVisible: true,
        appointmentCreationStatus: 'Requested',
        referenceNumberIsRequired: false,
        appointmentStartTimeMinutes: 30,
        appointmentStartTimeInterval: '30',
        muteAppointmentNotifications: false,
        mutedAppointmentNotifications: []
      },
      favoriteCarrierIds: null,
      customTags: null,
      expiresAt: '2023-05-21T05:13:00.371Z'
    }
  }
];

export const genericCustomFieldsMock: any[] = [
  {
    name: 'strText R',
    type: 'str',
    label: 'Text R',
    value: 'Mon Text',
    dropDownValues: []
  },
  {
    name: 'strText',
    type: 'str',
    label: 'Text',
    value: '',
    dropDownValues: []
  },
  {
    name: 'dateDate R',
    type: 'date',
    label: 'Date R',
    value: '2022-08-11',
    dropDownValues: []
  },
  {
    name: 'dateDate',
    type: 'date',
    label: 'Date',
    value: '2022-08-25',
    dropDownValues: []
  }
];
