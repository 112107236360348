<template>
  <div class="custom-field">
    <label class="custom-field__label" :class="{ required: field.required }" v-if="field.label">
      {{ `${field.label} ${field.description ? `- (${field.description})` : ''}` }}
    </label>

    <datepicker
      v-model="value"
      format="yyyy-MM-dd"
      :required="field.required"
      :state="!!value"
      model-type="yyyy-MM-dd"
      auto-apply
      :enable-time-picker="false"></datepicker>
  </div>
</template>

<script>
import customFieldTypeMixin from '@/components/custom_fields/mixins/customFieldTypeMixin';

export default {
  name: 'CustomFieldDate',
  mixins: [customFieldTypeMixin]
};
</script>

<style lang="scss" scoped>
:deep(.dp__input) {
  height: 56px;
  block-size: 56px;
}

:deep(svg.dp__icon.dp__input_icon) {
  margin-top: -28px;
}
</style>
