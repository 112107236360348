<template>
  <div class="custom-field">
    <label class="custom-field__label" :class="{ required: field.required }" v-if="field.label">
      {{ field.label }}
    </label>
    <v-text-field
      color="primary"
      variant="outlined"
      hide-details="auto"
      :type="type"
      :data-testid="field.testId"
      :required="field.required"
      :class="{ required: field.required }"
      v-model="value"
      clearable
      @keydown="handleKeydown"
      :title="field.description"
      :placeholder="field.placeholder ?? field.label"
      :persistent-placeholder="Boolean(field.placeholder)"
      :rules="validationRules"></v-text-field>
  </div>
</template>

<script>
import customFieldTypeMixin from '@/components/custom_fields/mixins/customFieldTypeMixin';
export default {
  props: {
    type: {
      type: String,
      required: false,
      default: 'text'
    }
  },
  mixins: [customFieldTypeMixin],
  methods: {
    handleKeydown(e) {
      const fieldType = this.novaCore.resolveCustomFieldType(this.field.type);
      const key = String(e.key).toLowerCase();

      if (
        fieldType === this.novaCore.CustomFieldType.Number &&
        this.disallowedNumberCharacters.includes(key)
      ) {
        e.preventDefault();
      }
    }
  }
};
</script>
