<template>
  <div class="d-flex flex-column gap-4">
    <div v-for="field in value" :key="field.name">
      <component
        :field="field"
        :disabled="field.disabled"
        v-bind:is="customFieldComponents[field.type]"
        v-model="field.value"
        :warehouse-id="warehouse.id"
        :timezone="warehouse.timezone"
        @input="v => updateFieldValue(v, field)"></component>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CustomFields',
  props: {
    warehouse: {
      type: Object,
      default: () => ({})
    },
    value: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    customFieldComponents() {
      // Auto generate component names for dynamic importing the Custom Fields components
      const componentsMap = {};
      Object.entries(this.novaCore.CustomFieldType).forEach(
        type => (componentsMap[type[1]] = `custom-field-${type[0].toLowerCase()}`)
      );
      return componentsMap;
    }
  },
  methods: {
    getRequiredValue(field) {
      this.novaCore.getRequiredValue(field, true);
    },
    updateFieldValue(value, field) {
      if (!value?.bubbles) {
        const fieldIdentifer = `${field.type}${field.label}`;
        this.value.find(c => `${c.type}${c.label}` === fieldIdentifer).value = value;
      }

      this.$emit('input', this.value);
    }
  }
};
</script>
