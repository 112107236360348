<template>
  <div class="d-flex flex-column gap-2">
    <document-upload-button
      :data-testid="getTestId(field)"
      :required="field.required"
      :rules="validationRules"
      :placeholder="field.description"
      :label="field.label"
      @uploaded="v => (field.value = v)"
      @cleared="field.value = null"
      :appointment-identifier="appointmentIdentifier"
      :warehouse-id="warehouseId"></document-upload-button>
    <p class="typography-body-sm text__color-text-secondary">
      Maximum
      <strong>50MB</strong>
      in size. Only
      <strong>ONE image</strong>
      ,
      <strong>text</strong>
      or
      <strong>spreadsheet</strong>
      allowed.
    </p>
  </div>
</template>

<script>
import customFieldTypeMixin from '@/components/custom_fields/mixins/customFieldTypeMixin';
import { kebabCase } from 'lodash';

export default {
  name: 'custom-field-document',
  mixins: [customFieldTypeMixin],
  props: {
    appointmentIdentifier: {
      type: String,
      required: false
    },
    warehouseId: {
      type: String,
      required: true
    }
  },
  methods: {
    getTestId(field) {
      return `${kebabCase(field.label)}-document-upload-button`;
    }
  }
};
</script>
