export function loadObjectFromLocalStorage(key: string): Record<string, unknown> {
  let data = {};
  if (localStorage.getItem(key)) {
    data = JSON.parse(String(localStorage.getItem(key)));
  }
  return data;
}

export function updateObjectInLocalStorage(key: string, val: object) {
  localStorage.setItem(key, JSON.stringify(val));
}

export function clearLocalStorageKey(key: string) {
  localStorage.removeItem(key);
}
