<template>
  <div class="custom-field">
    <label class="custom-field__label" :class="{ required: field.required }" v-if="field.label">
      {{ `${field.label} ${field.description ? `- (${field.description})` : ''}` }}
    </label>
    <v-switch
      dense
      :title="field.description"
      @input="value => $emit('input', value || '')"
      v-model="value"
      color="primary"
      :label="label"
      hide-details="auto"></v-switch>
  </div>
</template>

<script>
import customFieldTypeMixin from '@/components/custom_fields/mixins/customFieldTypeMixin';

export default {
  name: 'custom-field-bool',
  mixins: [customFieldTypeMixin],
  computed: {
    label() {
      return this.value ? 'Yes' : 'No';
    }
  }
};
</script>

<style scoped lang="scss">
:deep {
  .v-label {
    position: static !important;
    white-space: pre-wrap !important;
  }
  .v-input {
    margin-top: 0 !important;
  }
}
</style>
