type StrIsh = { toString: () => string };

/**
 * Compares two objects that implement the `toString` method for equality, ignoring case.
 *
 * @param a - The first object to compare.
 * @param b - The second object to compare.
 * @returns {boolean} Returns `true` if the string representations of both objects are equal.
 */
export function iEqual(a: StrIsh, b: StrIsh): boolean {
  return a.toString().toLowerCase() === b.toString().toLowerCase();
}

/**
 * Compares two alphanumeric strings for sorting purposes.
 *
 * @param strA - The first alphanumeric string to compare.
 * @param strB - The second alphanumeric string to compare.
 * @returns - Returns -1 if strA < strB, 1 if strA > strB, and 0 if they are equal.
 */
export function compareAlphaNumeric(strA: string, strB: string): number {
  const toChunks = (str: string): string[] => str.match(/[a-zA-Z]+|[0-9]+/gu) || [];
  const toValue = (str: string): number | string => (isNaN(Number(str)) ? str : Number(str));
  const compareChunk = (chunkA: string[], chunkB: string[]): number => {
    const len = Math.min(chunkA.length, chunkB.length);
    for (let i = 0; i < len; i++) {
      const a = toValue(chunkA.at(i) ?? '');
      const b = toValue(chunkB.at(i) ?? '');
      if (a === b) {
        // eslint-disable-next-line no-continue
        continue;
      }
      return a > b ? 1 : -1;
    }
    return chunkA.length - chunkB.length;
  };
  return compareChunk(toChunks(strA), toChunks(strB));
}
